<template>
    <!-- 条件筛选 多个开启 -->
    <div>
        <el-form :inline="true" ref="form" :model="queryForm" label-width="80px" size="small">
            <div class="top">
                <!-- 选择性出现 -->
                <el-form-item>
                    <el-select placeholder="请选择" v-model="queryForm.type" style="width: 110px;" @change="test">
                        <el-option v-for="item in options2" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="queryForm.keyword" placeholder="书名、作者、ISBN、益迪码" style="width: 285px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <!-- 传递出去 -->
                    <el-button type="primary" icon="el-icon-search" @click="querySubmitOut">查询</el-button>
                </el-form-item>
                <el-form-item style="position: absolute; right: 15px;">
                    <el-button icon="el-icon-time" @click="openCondition">筛选</el-button>
                    <el-button icon="el-icon-notebook-2">导出</el-button>
                </el-form-item>
            </div>
            <div class="middle" v-if="openStatus != false">
                <div style="display: flex; align-items: center;margin-right: 5px;">当</div>
                <el-select class="middle-item" v-model="conditionList[0].optionsValue1" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="middle-item" v-model="conditionList[0].optionsValue2" placeholder="请选择"
                    :disabled="conditionList[0].optionsValue1 == ''">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-input class="middle-item" v-model="conditionList[0].optionsValue3" placeholder="请输入内容"
                    :disabled="conditionList[0].optionsValue1 == ''"></el-input>
            </div>
            <!-- 点击添加筛选后增加的 -->
            <div class="middle" v-if="openStatus != false && conditionListBak.length != 0"
                v-for="(item, index) in conditionListBak">
                <div style="display: flex; align-items: center;margin-right: 5px;">且</div>
                <el-select class="middle-item" v-model="item.optionsValue1" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="middle-item" v-model="item.optionsValue2" placeholder="请选择"
                    :disabled="conditionList[0].optionsValue1 == ''">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-input class="middle-item" v-model="item.optionsValue3" placeholder="请输入内容"
                    :disabled="conditionList[0].optionsValue1 == ''"></el-input>
                <div class="circle" @click="delCondition">
                    _<div class="circle-item"></div>
                </div>
            </div>
            <div class="bottomBak" v-if="openStatus != false">
                <div class="txtBak" @click="addCondition">+添加筛选条件</div>
                <div class="right">
                    <el-button size="small" style="height: 35px;" @click="clearCondition">清空条件</el-button>
                    <el-button type="primary" size="small" style="height: 35px;" @click="startCondition">开始筛选</el-button>
                </div>
            </div>
            <!-- 横线 -->
            <el-divider></el-divider>
        </el-form>
    </div>
</template>

<script>
import { listLendRecord, } from '@/api/lendRecord'
import { listBook } from '@/api/book'

export default {
    props: {
        // 接收的搜索参数
        queryForm: {
            type: Object,
            default() {
                return {
                    // 按读者搜 按图书搜
                    type: '按读者搜',
                    // 书名、作者、ISBN、益迪码
                    keyword: ''
                }
            }
        },
        // 接收的分页数据
        pageForm: {
            type: Object,
            default() {
                return {
                    page: 1,// 当前页
                    size: 10,// 每页显示数
                    total: undefined// 总计
                }
            }
        },
        // 接收查询的状态 是否归还：0未归还,1已归还,2全部
        status: {
            type: String,
            default: '2'
        }
    },
    data() {
        return {
            form: {
                input: '',
                value: '',
                value1: '',
            },
            options: [{
                value: '黄金糕'
            }, {
                value: '臭豆腐'
            }],
            options1: [{
                value: '包含',
            }, {
                value: '为空',
            }, {
                value: '不包含',
            }],
            options2: [{
                value: 'reader',
                label: '按读者搜'
            }, {
                value: 'book',
                label: '按图书搜'
            }],
            // 筛选的条件
            conditionList: [{
                optionsValue1: "",
                optionsValue2: "",
                optionsValue3: ""
            }],
            conditionListBak: [],
            // 开启晒选条件判断
            openStatus: false,

            // 传递出去的数据
            tableDataOut: [],
            pageFormOut: {
                page: 1,// 当前页
                size: 10,// 每页显示数
                total: undefined// 总计
            }

        }
    },
    // 测试
    created() {
        this.getList()
    },
    methods: {
        test(data) {
            console.log(data);
            console.log(this.queryForm);
        },
        // 查询借阅记录列表  借阅记录
        getList() {
            const queryParams = { keyword: this.queryForm.key,type:0, page: this.pageForm.page, size: this.pageForm.size }
            listBook(queryParams).then(({ data }) => {
                this.tableDataOut = data.lendRecordData
                this.pageForm.total = data.total
            })
        },
        // 传递出去
        querySubmitOut() {
            this.getList();
            // 过滤条件的
            this.$emit('querySubmit', this.tableDataOut);
        },

        // 开启筛选条件
        openCondition() {
            this.openStatus = !this.openStatus;
        },
        // 添加条件
        addCondition() {
            this.conditionListBak.push({
                optionsValue1: "",
                optionsValue2: "",
                optionsValue3: ""
            })
            console.log(this.conditionListBak);
        },
        // 删除条件
        delCondition() {
            this.conditionListBak.pop()
            console.log(this.conditionList);
        },
        // 清空条件
        clearCondition() {
            this.conditionList = [{
                optionsValue1: "",
                optionsValue2: "",
                optionsValue3: ""
            }];
            this.conditionListBak = [];
        },
        // 开始筛选
        startCondition() { },
    }
}
</script>

<style scoped>
.top {
    display: flex;
}

/* 
.txt {
    border: 1px solid #dcdee2;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    cursor: pointer;
    border-radius: 4px;
    height: 32px;
    padding: 0 15px;
    color: #515a6e;
    background-color: #fff;
    width: 50px;
    margin-left: 10px;
} */

.middle {
    display: flex;
    margin-top: 15px;
}

.middle .middle-item {
    margin-left: 10px;
}

.middle .circle {
    font-size: 20px;
    color: rgb(237, 64, 20);
    position: relative;
    margin-left: 10px;
}

.middle .circle-item {
    position: absolute;
    border: 1px solid rgb(237, 64, 20);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: 14px;
    right: -2.5px;
    cursor: pointer;
}

.bottomBak {
    display: flex;
    margin-top: 15px;
}

.bottomBak .txtBak {
    color: #2d8cf0;
    cursor: pointer;
    line-height: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.bottomBak .right {
    display: flex;
}
</style>