import request from '@/utils/request'

// 查询app_Key和secret
export function oauthList() {
  return request({
    url: '/oauth2/queryKeysOnEntryPage',
    method: 'post',
  })
}
// 创建app_Key和secret
export function oauthAdd(data) {
  return request({
    url: '/oauth2/generateAppKey',
    method: 'post',
    data
  })
}
// 删除app_Key和secret
export function oauthDelete(data) {
  return request({
    url: '/oauth2/deleteAppKey',
    method: 'post',
    data
  })
}
// 启/禁用
export function oauthChangeStatus(data) {
  return request({
    url: '/oauth2/changeAppKeyStatus',
    method: 'post',
    data
  })
}
// 创建二级密码
export function oauthSetSecond(data) {
  return request({
    url: '/oauth2/setSecondPassword',
    method: 'post',
    data
  })
}

// 查询是否有二级密码
export function oauthExists() {
  return request({
    url: '/oauth2/existsSecondPassword',
    method: 'post',
  })
}